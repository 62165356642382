<template>
  <div>
    <h1>スケジュール検索</h1>
    <v-card outlined class="mb-5">
      <v-expansion-panels flat>
        <v-expansion-panel>
          <!-- Header -->
          <v-expansion-panel-header>
            <span class="headline">検索条件</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row style="height: 80px;">
              <v-col cols="5">
                <DatePicker
                  v-model="date"
                  label="対象年月"
                  type="month"
                  outlined
                  clearable
                  reactive
                />
              </v-col>
            </v-row>
            <v-row style="height: 80px;">
              <v-col cols="5">
                <DatePicker
                  v-model="scdateFrom"
                  label="作業予定日From"
                  prepend-inner-icon="mdi-calendar"
                  type="day"
                  outlined
                  clearable
                  reactive
                />
              </v-col>
              <v-col cols="1"> <p class="range-text">〜</p></v-col>
              <v-col cols="5">
                <DatePicker
                  v-model="scdateTo"
                  label="作業予定日To"
                  prepend-inner-icon="mdi-calendar"
                  type="day"
                  outlined
                  clearable
                  reactive
                />
              </v-col>
            </v-row>
            <v-row style="height: 80px;">
              <v-col cols="5">
                <DatePicker
                  v-model="exdateFrom"
                  label="作業実施日From"
                  prepend-inner-icon="mdi-calendar"
                  type="day"
                  outlined
                  clearable
                  reactive
                />
              </v-col>
              <v-col cols="1"> <p class="range-text">〜</p></v-col>
              <v-col cols="5">
                <DatePicker
                  v-model="exdateTo"
                  label="作業実施日To"
                  prepend-inner-icon="mdi-calendar"
                  type="day"
                  outlined
                  clearable
                  reactive
                />
              </v-col>
            </v-row>
            <v-row style="height: 80px;">
              <v-col cols="5">
                <v-autocomplete
                  v-model="selectHojin"
                  :items="Hojins"
                  outlined
                  label="法人選択"
                  item-text="name"
                  item-value="code"
                  chips
                  clearable
                  @change="changehojin"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="5">
                <v-autocomplete
                  v-model="selecthospital"
                  :items="hospitals"
                  outlined
                  label="病院選択"
                  item-text="name"
                  item-value="code"
                  chips
                  clearable
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row style="height: 80px;">
              <v-col cols="5">
                <v-autocomplete
                  v-model="user"
                  :items="users"
                  outlined
                  label="担当者選択"
                  item-text="name"
                  item-value="code"
                  chips
                  clearable
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="5">
                <v-autocomplete
                  v-model="selectFilterCompany"
                  :items="filterCompany"
                  outlined
                  label="協力会社選択"
                  item-text="name"
                  item-value="code"
                  chips
                  clearable
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row style="height: 30px;"> </v-row>
            <v-text-field
              v-model="floor"
              label="フロア"
              outlined
              prepend-inner-icon="mdi-transit-connection-variant"
              maxlength="255"
            ></v-text-field>
            <v-text-field
              v-model="area"
              label="エリア"
              outlined
              prepend-inner-icon="mdi-home-map-marker"
              maxlength="255"
            ></v-text-field>
            <v-text-field
              v-model="workarea"
              label="作業箇所"
              outlined
              prepend-inner-icon="mdi-close"
            />
            <v-col class="dialog-padding">
              <v-checkbox
                v-model="checknextcycle"
                label="予定が無い次回サイクルを含める"
              ></v-checkbox>
              <v-checkbox
                v-model="checkaddwork"
                label="追加箇所のある予定のみ検索"
              ></v-checkbox>
            </v-col>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions class="mx-2">
        <v-spacer></v-spacer>
        <Btn color="info" icon="file-import" @click="click('Search')">取得</Btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="!!schedule && schedule.length" class="office mt-5">
      <v-toolbar color="primary"> </v-toolbar>

      <template v-for="affiliation in affiliations">
        <table class="mt-4 mx-4" border="1" :key="affiliation.index">
          <thead>
            <tr>
              <th rowspan="1" class="fixed"></th>
              <th v-for="(cal, i) in schedule" :key="i">
                {{ cal.name }}
              </th>
            </tr>
          </thead>
          <template v-for="n in affiliation.scount">
            <template v-for="packagerow in affiliation.packages[n - 1]">
              <tr :key="packagerow.rowindex">
                <th v-if="packagerow.rowspan > 0" :rowspan="packagerow.rowspan">
                  <!-- <Btn
                    color="info"
                    text
                    small
                    @click="fix(affiliation.index, packagerow.index, 'query')"
                    > </Btn> -->
                </th>
                <template v-for="meisai in packagerow.packagemeisais">
                  <td
                    :key="meisai.rowindex"
                    v-if="meisai.rowspan > 0"
                    :rowspan="meisai.rowspan"
                    style="white-space: pre-line; word-wrap: break-word;"
                    :class="meisai.width"
                    :bgcolor="meisai.color"
                  >
                    {{ meisai.name }}
                  </td>
                </template>
              </tr>
            </template>
          </template>
        </table>
      </template>
    </v-card>
    <br />
    <v-row>
      <p style="color:red" class="text-h4 spacing-playground pa-3">
        <strong>{{ kensuutext }}</strong>
      </p>
    </v-row>
    <v-row>
      <p style="color:blue" class="text-h4 spacing-playground pa-3">
        <strong>{{ ninsuutext }}</strong>
      </p>
    </v-row>
    <v-row>
      <pre style="color:black" class="text-h4 spacing-playground pa-3">
        {{ shaintext }}
      </pre>
    </v-row>
    <v-row>
      <pre style="color:black" class="text-h4 spacing-playground pa-3">
        {{ helptext }}
      </pre>
    </v-row>
  </div>
</template>

<script>
// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import moment from "moment";
// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";
//import { VueGoodTable } from "vue-good-table";
import { CODE_GROUP as CodeGroup, NOT_SET_CODE as NotSetCode } from "@/defines";

import Api from "@/mixins/api";
import * as Dummy from "./dummy";

const ViewTypes = [{ text: "月", value: "month" }];

const Selects = {
  Cycle: 0,
  Any: 1
};

const DATE = new Date();
const DATENOW =
  DATE.getFullYear() +
  "-" +
  ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
  "-" +
  ("00" + Number(DATE.getDate())).slice(-2);
const Users = [];
export default {
  name: "ScheduleSearch",
  mixins: [Common, ShowDialogs, Api],
  components: {
    //"vue-timepicker": VueTimepicker
    //VueGoodTable
  },
  data: () => ({
    menu: false,
    date: "",
    scdateFrom: "",
    scdateTo: "",
    exdateFrom: "",
    exdateTo: "",
    select: Selects.Cycle,
    records: [],
    isConfirm: false,
    attendances: { rows: [], columns: [] },
    total: { rows: [], columns: [] },
    cols: [],
    rows: [],
    summaryhead: [],
    summary: [],
    Selects,
    options: {
      select: {
        enabled: true,
        selectOnCheckboxOnly: true,
        disableSelectInfo: true
      },
      search: { enabled: true }
    },
    selected: [],
    backForCalc: false,
    backForShift: false,
    backForApprovalStatus: false,
    showConfirm: false,
    hospitals: [],
    Hojins: [],
    users: [],
    filterCompany: [],
    schedule: [],
    workaffiliation: [],
    selectHojin: "",
    selecthospital: "",
    user: "",
    selectFilterCompany: "",
    floor: "",
    area: "",
    workarea: "",
    kensuutext: "",
    ninsuutext: "",
    checknextcycle: false,
    checkaddwork: false,
    shaintext: "",
    helptext: ""
  }),
  computed: {
    Year() {
      return !this.date ? undefined : parseInt(this.date.split("-")[0]);
    },
    Month() {
      return !this.date ? undefined : parseInt(this.date.split("-")[1]);
    },
    Day() {
      if (!this.date) return undefined;
      const split = this.date.split("-");

      return split.length < 2 ? undefined : parseInt(split[2]);
    }
  },
  methods: {
    async changehojin() {
      //法人コードが入った場合
      if (this.selectHojin != undefined) {
        const hp = this.getHospital();
        //const hp = await this.hospitals
        console.log(hp);
      } else {
        this.hospitals = [];
      }
    },
    rowStyleClass(row) {
      console.log("rowStyleClass", row);
      return "";
      //return row.remarks === "1" ? "yellow" : "";
    },
    async getTargetMonth() {
      const startmonth = new Date(this.Year, this.Month, 21);
      let targetdt = new Date(this.Year, this.Month, this.Day);

      const m = moment(targetdt);
      if (targetdt == startmonth) {
        // targetdt.setMonth(targetdt.getMonth() + 1);
        targetdt = m.add(1, "M").toDate();
      } else if (targetdt > startmonth && targetdt.getDate() < 21) {
        //targetdt.setMonth(targetdt.getMonth() - 1);
        targetdt = m.add(-1, "M").toDate();
      } else if (targetdt < startmonth && targetdt.getDate() < 21) {
        // targetdt.setMonth(targetdt.getMonth() - 1);
        targetdt = m.add(-1, "M").toDate();
      }

      let targetMonth =
        targetdt.getFullYear() +
        "-" +
        ("00" + Number(targetdt.getMonth())).slice(-2);

      if (Number(targetdt.getMonth()) == 0) {
        const m2 = moment(targetdt);
        targetdt = m2.add(-1, "M").toDate();
        targetMonth =
          targetdt.getFullYear() +
          "-" +
          ("00" + Number(targetdt.getMonth() + 1)).slice(-2);
      }

      return targetMonth;
    },
    async confirm() {
      this.isConfirm = await this.$confirm(
        "勤務表を提出します。\nよろしいですか？"
      );
    },
    async click(pathparam) {
      console.log("click", pathparam);

      console.log("this.date", this.date);
      console.log("this.scdateFrom", this.scdateFrom);
      console.log("this.scdateTo", this.scdateTo);
      console.log("this.exdateFrom", this.exdateFrom);
      console.log("this.exdateTo", this.exdateTo);
      console.log("this.selectHojin", this.selectHojin);
      console.log("this.selecthospital", this.selecthospital);
      console.log("this.user", this.user);
      console.log("this.selectFilterCompany", this.selectFilterCompany);

      //入力チェック
      //予定日は、From-Toが必須
      if (this.scdateFrom == "" || this.scdateTo == "") {
        if (
          (this.scdateFrom != "" && this.scdateTo == "") ||
          (this.scdateFrom == "" && this.scdateTo != "")
        ) {
          this.$error(
            "作業予定日のFrom-Toを両方入力するか、両方未入力のいずれかにしてください。"
          );
          return;
        }
      } else if (this.scdateFrom != "" && this.scdateTo != "") {
        const f = this.scdateFrom.split("-");
        const t = this.scdateTo.split("-");

        if (Number(f[0]) > Number(t[0])) {
          this.$error(
            "作業予定日のToは、Fromと同日か未来日を指定してください。"
          );
          return;
        } else if (Number(f[0]) == Number(t[0])) {
          if (Number(f[1]) > Number(t[1])) {
            this.$error(
              "作業予定日のToは、Fromと同日か未来日を指定してください。"
            );
            return;
          } else if (Number(f[1]) == Number(t[1])) {
            if (Number(f[2]) > Number(t[2])) {
              this.$error(
                "作業予定日のToは、Fromと同日か未来日を指定してください。"
              );
              return;
            }
          }
        }
      }

      //実施日は、From-Toが必須
      if (this.exdateFrom == "" || this.exdateTo == "") {
        if (
          (this.exdateFrom != "" && this.exdateTo == "") ||
          (this.exdateFrom == "" && this.exdateTo != "")
        ) {
          this.$error(
            "作業実施日のFrom-Toを両方入力するか、両方未入力のいずれかにしてください。"
          );
          return;
        }
      } else if (this.exdateFrom != "" && this.exdateTo != "") {
        const f = this.exdateFrom.split("-");
        const t = this.exdateTo.split("-");

        if (Number(f[0]) > Number(t[0])) {
          this.$error(
            "作業実施日のToは、Fromと同日か未来日を指定してください。"
          );
          return;
        } else if (Number(f[0]) == Number(t[0])) {
          if (Number(f[1]) > Number(t[1])) {
            this.$error(
              "作業実施日のToは、Fromと同日か未来日を指定してください。"
            );
            return;
          } else if (Number(f[1]) == Number(t[1])) {
            if (Number(f[2]) > Number(t[2])) {
              this.$error(
                "作業実施日のToは、Fromと同日か未来日を指定してください。"
              );
              return;
            }
          }
        }
      }

      //対象年月 or 作業予定日 or 作業実施日のいずれかは、必須
      if (this.scdateFrom == "" || this.scdateTo == "") {
        if (this.exdateFrom == "" || this.exdateTo == "") {
          if (this.date == "") {
            this.$error(
              "対象年月または、作業予定日または、作業実施日は、必須で入力してください。"
            );
            return;
          }
        }
      }

      const result = true;

      this.workaffiliation = [];

      if (result) {
        this.$loading();
        try {
          const schedule = await this.getShiftMonth(pathparam);

          this.schedule = schedule;
          this.affiliations = [];
          this.affiliations = this.workaffiliation;
          this.summaryheader = [];
          this.summaryheader = this.summaryhead;
          this.summaries = [];
          this.summaries = this.summary;

          console.log("schedule", this.schedule);
          this.tableTitle = this.format;
        } catch (e) {
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      }
    },
    async getShiftMonth(pathparam) {
      console.log("getShiftMonth", pathparam);
      // 選択した所属を取得
      //console.log("selected", this.selecthospital);
      this.workaffiliation = [];
      //this.packages.packagemeisais = [];
      //this.packages = [];
      //this.schedules = [];
      const targetmonth = [""];
      if (!this.date == "") {
        const date = new Date(this.date);
        targetmonth[0] =
          date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2);
      }

      const param = {
        affiliations: "",
        targetmonth: targetmonth[0],
        scheduleflag: "",
        unfinished: "",
        select: "",
        scdatefrom: this.scdateFrom,
        scedateto: this.scdateTo,
        exdatefrom: this.exdateFrom,
        exdateto: this.exdateTo,
        hojincd: this.selectHojin,
        hospitalcd: this.selecthospital,
        floor: this.floor,
        area: this.area,
        workarea: this.workarea,
        tantocd: this.user,
        company: this.selectFilterCompany,
        nextcycle: this.checknextcycle == true ? "1" : "0",
        addwork: this.checkaddwork == true ? "1" : "0"
      };
      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);
      console.log("this.packages", this.packages);
      // API送信
      const result = await this.$get(
        this.Paths.schedule,
        pathparam + "=" + encode
      );

      console.log("api_result", result);
      if (result.length == 0) {
        await this.$warning("該当するデータがありません。", "予定データ取得");
      } else {
        return await this.setShiftCalendar(result);
      }
    },
    async setShiftCalendar(apidata, year, month) {
      console.log("setShiftCalendar", apidata);

      //データヘッダを作成
      const shedder = apidata.schedulehedder;
      console.log("schedulehedder", shedder);

      const schedules = [];
      for (let i = 0; i < shedder.length; i++) {
        schedules.push({
          index: Number(shedder[i].code),
          name: shedder[i].name
        });
      }

      //
      const hedder = apidata.hedder;

      for (let i = 0; i < hedder.length; i++) {
        const affiliation = hedder[i];

        const packages = [];
        console.log("affiliations", this.affiliations);
        const rowscount = [0];
        const rowindex = [0];

        for (let a = 0; a < affiliation.details.length; a++) {
          const b = affiliation.details[a];
          rowscount[0] = rowscount[0] + b.packagemeisai.length;
        }

        for (let j = 0; j < affiliation.details.length; j++) {
          const c = affiliation.details[j];

          const bgcolor = [""];
          //行の色設定
          //作業日が入っていない場合は、色を付ける
          if (c.scheduledate == null || c.scheduledate == "") {
            bgcolor[0] = "";
          }

          const packageList = [];
          const meisairowspan = c.packagemeisai.length;

          const tanto = [""];
          const tantonm = [""];
          const wtanto = [""];
          const wtantonm = [""];

          //担当者のセット
          for (let d = 0; d < c.tanto.length; d++) {
            if (d > 0) {
              tanto[0] = tanto[0] + ",";
              tantonm[0] = tantonm[0] + ",\r\n";
            }
            tanto[0] = tanto[0] + c.tanto[d].userid;
            tantonm[0] = tantonm[0] + c.tanto[d].username;
          }

          //作業担当者のセット
          for (let e = 0; e < c.worktanto.length; e++) {
            if (e > 0) {
              wtanto[0] = wtanto[0] + ",";
              wtantonm[0] = wtantonm[0] + ",\r\n";
            }
            wtanto[0] = wtanto[0] + c.worktanto[e].userid;
            wtantonm[0] = wtantonm[0] + c.worktanto[e].username;
          }

          for (let k = 0; k < c.packagemeisai.length; k++) {
            if (j % 2 == 0) {
              bgcolor[0] = "white";
            } else {
              bgcolor[0] = "ECEFF1";
            }
            const s = c.packagemeisai[k];
            const packagemeisaiList = [];
            const stime = c.startTime.split(":");
            const etime = c.endTime.split(":");

            const prows = [0];

            if (k == 0) {
              prows[0] = meisairowspan;
            }

            //物件名～フロアまでは、固定
            //物件名
            if (j > 0 || k > 0) {
              packagemeisaiList.push({
                rowspan: 0,
                status: year + "-" + ("00" + month).slice(-2),
                code: "",
                sepalate: "",
                name: affiliation.hospitalName,
                width: "hsnm",
                color: bgcolor[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: rowscount[0],
                status: year + "-" + ("00" + month).slice(-2),
                code: "",
                sepalate: "",
                name: affiliation.hospitalName,
                width: "hsnm",
                color: bgcolor[0]
              });
            }
            //作業年月
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: c.scheduleMonth,
              width: "common",
              color: bgcolor[0]
            });
            //作業日時
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: c.scheduledate,
              width: "common",
              color: bgcolor[0]
            });
            //作業実施日時
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: c.executiondate,
              width: "common",
              color: bgcolor[0]
            });
            //開始時刻
            if (stime.length > 1) {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: stime[0] + ":" + stime[1],
                width: "scommon",
                color: bgcolor[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: "",
                width: "scommon",
                color: bgcolor[0]
              });
            }
            //終了時刻
            if (etime.length > 1) {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: etime[0] + ":" + etime[1],
                width: "scommon",
                color: bgcolor[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: "",
                width: "scommon",
                color: bgcolor[0]
              });
            }
            //作業内容
            packagemeisaiList.push({
              rowspan: s.rowspan,
              status: "",
              code: "",
              sepalate: "",
              name: s.workpattern,
              width: "common",
              color: bgcolor[0]
            });
            //フロア
            packagemeisaiList.push({
              rowspan: s.rowspan,
              name: s.floor,
              width: "common",
              color: bgcolor[0]
            });
            //エリア
            packagemeisaiList.push({
              rowspan: s.rowspan,
              name: s.area,
              width: "common",
              color: bgcolor[0]
            });
            //作業箇所
            const warea = [""];
            /*const st = [""];
            const add = [""];
            const sep = [""];
            const key = [""];
            const ref = [""];
            const other = [""];*/
            /*if (s.workarea[0].processingscheduleother != null) {
              other[0] = s.workarea[0].processingscheduleother;
            }*/
            for (let l = 0; l < s.workarea.length; l++) {
              if (l > 0) {
                warea[0] = warea[0] + ",";
                /*st[0] = st[0] + ",";
                add[0] = add[0] + ",";
                sep[0] = sep[0] + ",";
                key[0] = key[0] + ",";
                ref[0] = ref[0] + ",";
                other[0] = other[0] + ",";*/
              }
              warea[0] = warea[0] + s.workarea[l].workarea;
              /*st[0] = st[0] + s.workarea[l].displayflag;
              add[0] = add[0] + s.workarea[l].addflag;
              sep[0] = sep[0] + s.workarea[l].sepalationflag;
              key[0] = key[0] + s.workarea[l].keyid;
              ref[0] = ref[0] + s.workarea[l].referenceworkareaid;
              other[0] = other[0] + s.workarea[l].processingscheduleother;*/
            }
            //作業箇所
            packagemeisaiList.push({
              rowspan: 1,
              status: "", //st[0],
              code: "", //add[0],
              sepalate: "", //sep[0],
              name: warea[0],
              keyid: "", //key[0],
              referenceid: "", //ref[0],
              width: "workarea",
              color: bgcolor[0],
              other: "" //other[0]
            });
            //作業担当者
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: wtanto[0],
              sepalate: "",
              name: wtantonm[0],
              width: "common",
              color: bgcolor[0]
            });
            //担当者
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: tanto[0],
              sepalate: "",
              name: tantonm[0],
              width: "common",
              color: bgcolor[0]
            });
            //残理由
            const rb = [""];
            if (c.remainreson == "1") {
              rb[0] = "病院側より要請";
            } else if (c.remainreson == "2") {
              rb[0] = "自社都合";
            } else if (c.remainreson == "3") {
              rb[0] = "病院側より要請・自社都合";
            }
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: rb[0],
              width: "common",
              color: bgcolor[0]
            });
            //同日作業情報
            packagemeisaiList.push({
              rowspan: prows[0],
              name: c.wksuu,
              width: "scommon",
              color: bgcolor[0]
            });

            //以下の内容は、画面には表示させないがデータ処理場必須のため渡す(必須:rowspan=0)

            if (k == 0) {
              packageList.push({
                index: j,
                rowindex: rowindex[0],
                hojinCd: affiliation.hojinCd,
                hospitalCd: affiliation.hospitalCd,
                packageNo: c.packageNo,
                packageMeisaiNo: s.packageMeisaiNo,
                packagemeisais: packagemeisaiList,
                rowspan: meisairowspan,
                unfinished: false,
                keyId: c.keyid,
                yotei: ""
              });
            } else {
              packageList.push({
                index: j,
                rowindex: rowindex[0],
                hojinCd: affiliation.hojinCd,
                hospitalCd: affiliation.hospitalCd,
                packageNo: c.packageNo,
                packageMeisaiNo: s.packageMeisaiNo,
                packagemeisais: packagemeisaiList,
                rowspan: 0,
                unfinished: false,
                keyId: c.keyid,
                yotei: ""
              });
            }
            rowindex[0] = rowindex[0] + 1;
          }

          packages.push(packageList);
          console.log("packages", this.packages);
        }

        const scounts = packages.length;

        this.workaffiliation.push({
          hojinCd: affiliation.hojinCd,
          hospitalCd: affiliation.hospitalCd,
          hospitalName: affiliation.hospitalName,
          index: i,
          packages: packages,
          scount: scounts
        });
      }

      //this.scount = this.packages.length;
      console.log("scount", this.scount);

      const schedule = schedules;
      console.log("affiliations", this.affiliations);

      //合計項目を作成する。
      //人数合計の内容を作成
      const textninsuu = ["合計人数:"];
      for (let i = 0; i < apidata.people.length; i++) {
        const pp = apidata.people[i];
        textninsuu[0] = textninsuu[0] + "\n";
        textninsuu[0] = textninsuu[0] + pp.companyNm + ": " + pp.ninsuu + " 人";
      }

      this.kensuutext = "合計件数:" + apidata.kensuu + "件";

      this.ninsuutext = textninsuu[0];

      //自社社員・協力会社社員の実績データを取得、表示する。
      const shaintext = [
        "\n実施日,物件,応援業者,作業者,集合時間,開始時間,終了時間,帰社時間,休憩時間実績,作業時間"
      ];
      for (let i = 0; i < apidata.shaindata.length; i++) {
        const a = apidata.shaindata[i];
        shaintext[0] = shaintext[0] + "\n";
        shaintext[0] = shaintext[0] + a.dateStr;
        shaintext[0] = shaintext[0] + "," + a.hospital;
        shaintext[0] = shaintext[0] + "," + a.helpnm;
        shaintext[0] = shaintext[0] + "," + a.human;
        shaintext[0] = shaintext[0] + "," + a.assemblytime;
        shaintext[0] = shaintext[0] + "," + a.starttime;
        shaintext[0] = shaintext[0] + "," + a.endtime;
        shaintext[0] = shaintext[0] + "," + a.leavetime;
        shaintext[0] = shaintext[0] + "," + a.resttime;
        shaintext[0] = shaintext[0] + "," + a.worktime;
      }

      this.shaintext = shaintext[0];

      const helptext = [
        "\n実施日,物件,応援業者,作業者,集合時間,開始時間,終了時間,帰社時間,休憩時間実績,作業時間"
      ];
      for (let i = 0; i < apidata.helpdata.length; i++) {
        const a = apidata.helpdata[i];
        helptext[0] = helptext[0] + "\n";
        helptext[0] = helptext[0] + a.dateStr;
        helptext[0] = helptext[0] + "," + a.hospital;
        helptext[0] = helptext[0] + "," + a.helpnm;
        helptext[0] = helptext[0] + "," + a.human;
        helptext[0] = helptext[0] + "," + a.assemblytime;
        helptext[0] = helptext[0] + "," + a.starttime;
        helptext[0] = helptext[0] + "," + a.endtime;
        helptext[0] = helptext[0] + "," + a.leavetime;
        helptext[0] = helptext[0] + "," + a.resttime;
        helptext[0] = helptext[0] + "," + a.worktime;
      }

      this.helptext = helptext[0];

      return schedule;
    },
    async setUpdateData(pcks, aindex) {
      this.$loading();
      console.log("setUpdateData", pcks);
      const affilitations = this.affiliations;
      const schedules = this.schedule;

      this.affiliations = [];
      //this.packages = [];
      this.schedule = [];

      this.schedule = schedules;
      affilitations[aindex].packages = pcks;
      //this.affiliations[aindex].packages = pcks;
      this.affiliations = affilitations;
      //this.packages = pcks;

      this.$unloading();
    },
    async getData(api) {
      let data;
      switch (this.select) {
        default: {
          if (api) {
            this.$loading();

            try {
              data = await this.getRecordByUser();
              const bodies = [];
              data.body.forEach(e => {
                if (e.recordid !== null) bodies.push(e);
              });
              data.body = bodies;
            } catch (e) {
              console.log(e);
              this.$error(e.message);
            } finally {
              this.$unloading();
            }
          } else {
            data = Dummy.getPrivateRostar();
          }
          break;
        }
      }
      await this.setAttendanceDetails(data);
    },
    async getRecordByUser() {
      const targetMonth = await this.getTargetMonth();
      const param = {
        incode: this.user.code ? this.user.code : this.user,
        targetmonth: targetMonth
      };
      console.log("getRecordByUser", param);
      const encode = encodeURI(JSON.stringify(param));
      return await this.$get(this.Paths.attendance, "query=" + encode);
    },
    async getRecordByOffice() {
      const param = {
        affiliationId: this.shozoku.code ? this.shozoku.code : this.shozoku,
        attendanceDateFrom: this.dateFrom,
        attendanceDateTo: this.dateTo
      };
      console.log("getRecordByOffice", param);
      const encode = encodeURI(JSON.stringify(param));
      return await this.$get(this.Paths.attendance, "query-office=" + encode);
    },
    async getCodeMasterItems() {
      console.log("getExist");
      this.codeMasterItems = await this.$get(this.Paths.codeMasterPulldown);
    },
    getPullDownItems() {
      console.log("getPullDownItems");
      let filter = [];

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.HJN);
      this.Hojins = filter.filter(e => e.code !== NotSetCode.HJN);

      filter = this.codeMasterItems.filter(e => e.refcode === CodeGroup.HLP);
      this.filterCompany = filter.filter(e => e.code !== NotSetCode.HLP);
    },
    async getHospital() {
      console.log("getHospital");

      const param = {
        hojinCd: this.selectHojin ? this.selectHojin : ""
      };

      console.log("getHospital", param);
      const encode = encodeURI(JSON.stringify(param));

      const test = await this.$get(
        this.Paths.hospitalfilter,
        "query=" + encode
      );

      console.log("test", test);

      this.hospitals = test;

      return test;

      //return await this.$get(this.Paths.hospitalfilter, "query=" + encode);
    },
    async getUsers() {
      console.log("getUsers");
      return await this.$get(this.Paths.shainFilter);
    }
  },
  async created() {
    console.log("created", this.date);

    this.$loading();

    try {
      ////await this.getUsers();
      //// await this.getShozoku();
      //this.setdate();
    } finally {
      this.$unloading();
    }

    await this.getCodeMasterItems();
    this.getPullDownItems();
    this.users = await this.getUsers();

    //this.select = Selects.Cycle;
  },
  mounted() {
    console.log("mounted");
  }
};
</script>

<style></style>

<style lang="scss" scoped>
.office {
  table {
    //width: 99%;
    border-collapse: collapse;
    display: block;
  }
}
.hsnm {
  width: 9%;
}
.workarea {
  width: 25%;
}
.common {
  width: 6%;
}
.scommon {
  width: 4%;
}
.sum {
  width: 50%;
}
.bold-text {
  font-weight: bold;
}
.pulldown-z-index {
  z-index: 3;
}
.fixed {
  position: sticky;
  left: 0;
  color: #333;
  background-color: white;
  z-index: 2;
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
  }
}
.private {
  table {
    width: 100%;
  }
}
.person {
  width: 50px;
}
.person::after {
  content: "ms";
}
.shift-event {
  font-size: 12px;
}

.warning-col {
  background-color: #ffcc66;
}

.worktime-span {
  float: right;
  margin-right: 15px;
}

.range-text {
  text-align: center;
  line-height: 50px;
  height: 50px;
}
.txt {
  white-space: pre-wrap;
}
</style>
